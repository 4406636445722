// frontend/src/pages/Register.js
import React from 'react';

function Register() {
  return (
    <div style={{padding:'20px'}}>
      <h2>Register</h2>
      <p>Registration is not available. Please contact the administrator.</p>
    </div>
  );
}

export default Register;
