// frontend/src/pages/ManageWallets.js
import React from 'react';

function ManageWallets() {
  return (
    <div style={{padding:'20px'}}>
      <h2>Manage Wallets (No backend, no real functionality)</h2>
      <p>Use the Dashboard to create wallets and simulate balances.</p>
    </div>
  );
}

export default ManageWallets;
