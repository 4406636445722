// frontend/src/pages/History.js
import React from 'react';

function History() {
  return (
    <div style={{ padding: '20px' }}>
      <h2>History</h2>
      <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:'40px' }}>
        <h4 style={{textAlign:'center'}}>Error loading data at this time.</h4>
      </div>
    </div>
  );
}

export default History;
